import { useEffect, useRef, useState } from 'react';
import './App.css';

const templeAddress = `
ISKCON Mysore, No. 213, 18th cross, Jayanagar, Mysore - 570014
Contact: 8147701807
`.trim().toUpperCase()

const Box = ({ donor }) => {

  var [fs, setFs] = useState(0.2)
  var box = useRef()

  useEffect(() => {
    if (box.current.scrollHeight > box.current.clientHeight) {
      if (fs <= 0) {
        return
      }
      setFs(fs => fs - 0.005)
    }
  }, [fs])

  return (
    <div className='box' ref={box} style={{ fontSize: `${fs}in`, whiteSpace: 'break-spaces' }}>
      <div>To,</div>
      <div>{donor[0]}</div>
      <div>{donor[7]}</div>
      <div>PHONE: {donor[2]}</div>
      <hr />
      <div>From,</div>
      <div>{templeAddress}</div>
      <hr />
      <div className='box-code'>{`ID:58382/${(donor[1].padStart(4, "0"))}/${donor[6]}/T${donor[5]}/B${donor[3]}/ST${donor[4]}`}</div>
    </div>
  )
}

const Page = ({ id, className, children }) => {
  return (
    <div className={`page ${className}`} id={id}>
      {children}
      <span style={{ pageBreakAfter: "always" }}></span>
    </div>
  )
}


const App = () => {

  var [donors, setDonors] = useState([])

  const onSubmit = e => { setDonors(document.getElementById("ldata").value.split("\n").filter(l => !!l.trim())) }

  const createShards = (array, shardLen) => {
    let shards = []
    for (let i = 0; i < array.length; i += shardLen) {
      shards.push(array.slice(i, i + shardLen))
    }
    return shards
  }

  return (
    <div className="App">
      <div>
        <textarea id="ldata" />
        <button onClick={onSubmit}>Submit</button>
      </div>
      <div style={{ pageBreakAfter: "always" }}></div>
      {
        createShards(donors, 6).map(shard => {
          return (
            <Page>{shard.map(donor => {
              return <div className='box-cont'>
                <Box donor={donor.split("§")} />
                <div className='ems-label'/>
              </div>
            })}</Page>
          )
        })
      }
    </div>
  )
}

export default App;
